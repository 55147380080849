<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    url: string
    title: string
    onlyTitle?: boolean
    description?: string
    keywords?: string
    image?: string
  }>(),
  {
    description:
      '창업부터 성장까지 필요한 모든 것. SaaS, 교육, 컨설팅으로 비즈니스를 가속화하세요.',
    keywords: '스타트업 성장 엔진',
    image:
      'https://zillinks.s3.ap-northeast-2.amazonaws.com/assets/images/seo/default.webp'
  }
)
const config = useRuntimeConfig()

useHead({
  titleTemplate: props.onlyTitle
    ? '%s'
    : `${props.title.length ? '%s | ' : ''}질링스`,
  script: [
    {
      type: 'application/ld+json',
      innerHTML: `{
        "@context": "http://schema.org",
        "@type": "Organization",
        "image": "${props.image}",
        "url": "${config.public.CLIENT_CURRENT_URL}${props.url}",
        "sameAs": ["https://www.zillinks.com", "https://app.zillinks.com"],
        "logo": "${props.image}",
        "name": "${props.title}",
        "description": "${props.description.replaceAll('"', '\\"')}",
        "email": "contact@zillinks.com"
      }`
    }
  ]
})
</script>

<template>
  <Head>
    <Title>{{ title }}</Title>
    <Link
      rel="canonical"
      :href="`${config.public.CLIENT_CURRENT_URL}${url}`" />
    <Meta
      name="description"
      :content="description" />
    <Meta
      name="author"
      content="Zillinks" />
    <Meta
      name="keywords"
      :content="keywords" />
    <Meta
      property="og:title"
      :content="title" />
    <Meta
      property="og:type"
      content="business.business" />
    <Meta
      property="og:image"
      :content="image" />
    <Meta
      property="og:url"
      :content="`${config.public.CLIENT_CURRENT_URL}${url}`" />
    <Meta
      property="og:description"
      :content="description" />
    <Meta
      property="og:site_name"
      content="질링스" />
    <Meta
      property="og:locale"
      content="ko_KR" />

    <Meta
      name="twitter:card"
      content="summary_large_image" />
    <Meta
      name="twitter:site"
      content="@Zillinks" />
    <Meta
      name="twitter:title"
      :content="title" />
    <Meta
      name="twitter:description"
      :content="description" />
    <Meta
      name="twitter:image"
      :content="image" />
    <Meta
      name="twitter:url"
      :content="`${config.public.CLIENT_CURRENT_URL}${url}`" />
  </Head>
</template>
